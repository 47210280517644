<template>
  <section class="container sign-container">
    <div class="w-100 sign-container" v-if="!passwordConfirmed">
      <img src="../assets/images/forgot-password.svg" alt="">
      <h1 class="text-center fs-1 fw-bold">Reset password</h1>

      <div class="col-xl-3 col-lg-3 col-md-6 create-account-fields-wrapper">
        <div class="sign-field-block w-100">
          <h6>Password</h6>
          <input type="password" class="w-100 sign-field" v-model="userPassword" placeholder="Enter your password">
        </div>

        <div class="sign-field-block w-100">
          <h6>Confirm password</h6>
          <input type="confirm-password" class="w-100 sign-field" v-model="userPasswordConfirmed" placeholder="Confirm your password">
        </div>

        <p v-if="passwordError" class="text-danger">{{ passwordError }}</p>

        <div class="w-100 sign-button-block">
          <button class="next-step-button" @click="resetPassword()">Reset password</button>
        </div>

      </div>
    </div>
    <div v-else class="w-100 sign-container">
      <img src="../assets/images/password-confirm.svg" alt="">
      <p class="text-center fw-bold">We sent you a message with a new password.</p>
    </div>

  </section>
</template>

<script>
import axios from '@/axios';

export default{
  data(){
    return{
      userPassword: '',
      userPasswordConfirmed: '',
      passwordConfirmed: false,
      passwordError: false,
      token: '',
      email: '',
    }
  },
  mounted(){
    this.token = this.$route.query.token;
    this.email = this.$route.query.email;
  },
  methods: {
    validatePasswords(){
      if (this.userPassword !== this.userPasswordConfirmed) {
        this.passwordError = 'Passwords do not match.';
        return false;
      }
      this.passwordError = ''; // Clear error if passwords match
      return true;
    },
    async resetPassword() {
      if (!this.validatePasswords()) {
        return; // Stop if passwords do not match
      }

      try {
        await axios.post('/client/password/reset', {
          token: this.token,
          email: this.email,
          password: this.userPassword,
          password_confirmation: this.userPasswordConfirmed,
        }).then((response) => { // использование стрелочной функции здесь
          console.log(response);
          console.log(response.data);
          if(response.data.success === true) {
            this.$router.push('/sign-in');
          }



        })
      } catch (error) {
        console.log(error);
        // this.$router.push('/');
        // console.error('Ошибка при получении данных от API:', error)
        this.passwordError = 'Something went wrong';
        return false;
      }
    }
  }
}
</script>

<style scoped>

.sign-field{
  background: #F3F3F6;
  height: 44px;
  border: 1px solid #E6E8ED;
  border-radius: 8px;
  padding: 10px 12px 10px 12px;
  line-height: 24px;
}

h6{
  font-weight: 600;
}

.already-signed-text{
  margin-bottom: 0px;
}

.privacy-text{
  font-size: 14px;
}

.sign-container{
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 60px;
  margin-bottom: 235px;
}

.sign-button-block{
  padding: 0px 16px 0px 16px;
  margin-bottom: 32px;
}

.create-account-fields-wrapper{
  margin-top: 32px;
}

.sign-field-block{
  margin-bottom: 24px;
}

</style>